.error-page {
    color: white;
    width: 100vw;
    height: 100vh;
}

.error-page-text-container {
    height: calc(100vh - 50px);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-page-text {
    width: fit-content;
    height: fit-content;
    padding: 5px;
    text-align: center;
}
