.project-card {
    max-width: 1100px;
    min-height: 300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* background: red; */
    padding: 15px 0;
    cursor: pointer;
    /* margin-bottom: 15px; */
}

.project-card:hover {
    background: #e6e6e6;
}

.project-card-image-container {
    /* background: aqua; */
    flex: .35;
    height: 300px;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
}

.project-card-image-container > img {
    width: 352px;
    height: 300px;
    object-fit: cover;
    /* border-radius: 50%; */
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.5);
}

.project-card-text-container {
    /* background: orange; */
    flex: .65;
    /* min-height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.project-card-text {
    /* background: aqua; */
    height: fit-content;
    max-width: 640px;
    min-width: 350px;
    margin-top: 5px;
}

.project-card-text > h2 {
    font-size: 32px;
}

.project-card-text > p {
    font-size: 18px;
}