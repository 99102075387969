
* {
  margin: 0;
  padding: 0;
}

:root {
  --title-height: 50px;
  --footer-height: 50px;
}

body {
  height: 100vh;
  width: 100vw;
  background: url("../images/blue-ocean-compressed.jpg");
  background-size: cover;
  background-position: bottom;
  display: block;
}

.site-landing {
  display: block;
  height: 100vh;
  width: 100vw;
}

.site-landing-bottom {
  /* background: aqua; */
  max-width: 1800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - (var(--title-height)));
}

.site-items-container {
  /* background: blue; */
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 1600px) {
  .site-items-container {
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: start;
  }
}

footer {
  color: white;
  height: var(--footer-height);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 5px;
}