:root {
    --corner-box-height: calc(100vh - 100px);
    --corner-box-radius: 25px;
    --corner-box-color: #f2f2f2;
}

.corner-box {
    max-width: 1500px;
    height: var(--corner-box-height);
    margin: 0 auto;
    background: var(--corner-box-color);
    display: flex;
    flex-direction: column;
    border-radius: var(--corner-box-radius);
    margin-top: 3px;
}

.corner-box-title {
    min-height: 75px;
    display: flex;
    align-items: center;
    font-size: 36px;
    margin: 0 25px;
}
.corner-box-content-container {
    flex: 1;
    margin-bottom: 22px;
    margin-top: -10px;
    overflow-y: auto;
}